import * as React from "react"

export default function Twitter(props) {
  return (
    <svg width={44} height={36} viewBox="0 0 44 36" {...props}>
      <path
        fill="#1DA1F2"
        d="M13.837 36c16.604 0 25.686-13.851 25.686-25.863 0-.394 0-.785-.027-1.175A18.438 18.438 0 0044 4.257a17.922 17.922 0 01-5.185 1.43A9.11 9.11 0 0042.784.659a18.016 18.016 0 01-5.732 2.207 8.99 8.99 0 00-10.846-1.79c-3.602 1.942-5.462 6.073-4.539 10.08A25.57 25.57 0 013.062 1.659C.666 5.812 1.89 11.125 5.857 13.793a8.913 8.913 0 01-4.097-1.138v.115c.001 4.327 3.03 8.054 7.242 8.91a8.953 8.953 0 01-4.076.157c1.183 3.703 4.572 6.24 8.434 6.312A18.035 18.035 0 010 31.917a25.429 25.429 0 0013.837 4.076"
      />
    </svg>
  )
}
