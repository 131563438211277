import * as React from "react"

export default function JohnAvatar(props) {
  return (
    <svg width="464px" height="516px" viewBox="0 0 464 516" {...props}>
      <defs>
        <linearGradient
          x1="0.292203959%"
          y1="0%"
          x2="89.1055375%"
          y2="100%"
          id="linearGradient-1"
        >
          <stop stopColor="#F64F59" offset="0%" />
          <stop stopColor="#C471ED" offset="36.7733829%" />
          <stop stopColor="#12C2E9" offset="100%" />
        </linearGradient>
        <path
          d="M72.995 254.27c34 0 43.543 30.418 69.043 108.418 25.5 78 127 80.486 72.962 128.581-54.037 48.095-215.001 24-215.001-110.5 0-105.638 38.996-126.5 72.996-126.5zM227.5.27c163.813 0 226.87 235.299 226.87 305.377 0 70.08-36.782 120.239-143.625 120.239-119.885 0-107.287-69.4-139.245-135.117-36.352-74.752-108.502-90-108.502-173.5 0-98.545 57.2-117 164.502-117z"
          id="path-2"
        />
        <filter
          x="-4.8%"
          y="-4.3%"
          width="109.7%"
          height="108.6%"
          filterUnits="objectBoundingBox"
          id="filter-4"
        >
          <feGaussianBlur
            stdDeviation={20}
            in="SourceAlpha"
            result="shadowBlurInner1"
          />
          <feOffset dy={-4} in="shadowBlurInner1" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2={-1}
            k3={1}
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0"
            in="shadowInnerInner1"
          />
        </filter>
        <path
          d="M124 0v46h4c39.765 0 72 32.235 72 72v9H0v-9c0-39.765 32.235-72 72-72h4V0h48z"
          id="path-5"
        />
        <path
          d="M151.001.071c17.206 1.926 30.616 7.051 31.898 19.935L182 20c39.765 0 72 32.235 72 72v78H0V92c0-39.465 31.751-71.513 71.102-71.995C72.383 7.122 85.793 1.996 103 .071V19c0 13.255 10.745 24 24 24s24-10.745 24-24z"
          id="path-7"
        />
        <filter
          x="-309.7%"
          y="-309.7%"
          width="719.4%"
          height="719.4%"
          filterUnits="objectBoundingBox"
          id="filter-9"
        >
          <feGaussianBlur stdDeviation={24} in="SourceGraphic" />
        </filter>
        <filter
          x="-309.7%"
          y="-309.7%"
          width="719.4%"
          height="719.4%"
          filterUnits="objectBoundingBox"
          id="filter-10"
        >
          <feGaussianBlur stdDeviation={24} in="SourceGraphic" />
        </filter>
        <filter
          x="-93.7%"
          y="-38.5%"
          width="287.5%"
          height="176.9%"
          filterUnits="objectBoundingBox"
          id="filter-11"
        >
          <feGaussianBlur stdDeviation={3.75} in="SourceGraphic" />
        </filter>
        <filter
          x="-93.7%"
          y="-38.5%"
          width="287.5%"
          height="176.9%"
          filterUnits="objectBoundingBox"
          id="filter-12"
        >
          <feGaussianBlur stdDeviation={3.75} in="SourceGraphic" />
        </filter>
        <path
          d="M.476 3.332C2.004 15.305 13.632 24.614 27.75 24.614c14.162 0 25.818-9.367 27.288-21.393.118-.968-1.12-2.573-2.67-2.573H3.32C1.76.648.323 2.134.476 3.332z"
          id="path-13"
        />
        <linearGradient
          x1="52.7404329%"
          y1="18.2871594%"
          x2="47.2595671%"
          y2="81.7128406%"
          id="linearGradient-15"
        >
          <stop stopColor="#ECB48D" stopOpacity={0.0739182692} offset="0%" />
          <stop stopColor="#EBB38C" offset="100%" />
        </linearGradient>
        <path
          d="M13.335 22.455c7.78.55 14.434-4.02 14.861-10.208C28.623 6.06 22.663.596 14.883.045 7.103-.505.449 4.065.022 10.253c-.428 6.188 5.533 11.651 13.313 12.202z"
          id="path-16"
        />
        <filter
          x="-58.5%"
          y="-37.8%"
          width="216.9%"
          height="251.1%"
          filterUnits="objectBoundingBox"
          id="filter-17"
        >
          <feOffset dy={9} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={4}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.259915865 0"
            in="shadowBlurOuter1"
          />
        </filter>
        <filter
          x="-46.8%"
          y="-133.3%"
          width="193.6%"
          height="366.6%"
          filterUnits="objectBoundingBox"
          id="filter-18"
        >
          <feGaussianBlur stdDeviation={3} in="SourceGraphic" />
        </filter>
        <filter
          x="-194.7%"
          y="-171.4%"
          width="489.3%"
          height="442.9%"
          filterUnits="objectBoundingBox"
          id="filter-19"
        >
          <feGaussianBlur stdDeviation={3} in="SourceGraphic" />
        </filter>
        <path
          d="M37.01 19.49c-.248 4.963-8.777 3.094-18.477.78-9.7-2.314-11.574 1.905-18.27-6.564C-1.441 11.307 5.179-1.106 18.534.08 36.616 1.127 37.01 17.266 37.01 19.49z"
          id="path-20"
        />
        <linearGradient
          x1="48.3277447%"
          y1="37.1652354%"
          x2="100%"
          y2="100%"
          id="linearGradient-22"
        >
          <stop stopColor="#87A1B2" offset="0%" />
          <stop stopColor="#BCCFD9" offset="100%" />
        </linearGradient>
        <path
          d="M0 19.49c.112 2.245 1.92 3.092 4.763 3.132h.457c.155-.002.313-.006.474-.013l.49-.026.506-.037.52-.049.536-.06.548-.07.562-.079.575-.089.586-.098.598-.106.918-.173.94-.189 1.285-.273 1.65-.37 2.723-.638.346-.082a29.793 29.793 0 013.59-.634l.573-.06.551-.045.531-.036.76-.036 1.182-.031 1.3-.024.61-.02.396-.02.388-.03c.064-.005.127-.011.19-.018l.378-.044c2.438-.324 4.556-1.438 7.82-5.566.313-.44.346-1.22.107-2.19l-.09-.33a8.513 8.513 0 00-.109-.342l-.13-.355a13.402 13.402 0 00-1.32-2.512l-.277-.405-.295-.406C31.734 3.3 26.245-.61 18.477.08 6.904.75 2.576 7.602.96 13.032l-.144.504-.128.495-.06.243-.107.478-.094.465a23.75 23.75 0 00-.043.226l-.111.657-.06.417-.052.398-.06.558-.043.508-.036.592-.014.373L0 19.49z"
          id="path-23"
        />
        <path
          d="M83.513.215c18.91 1.621 34.252 7.82 48.434 20.047 5.531 5.565 15.213 17.39 20.278 33.823-1.105 1.27-2.49 1.906-4.154 1.906-1.284 0-36.783-6.791-71.516-9.615-32.82-2.668-64.972-1.378-66.418-1.378-1.984 0-3.272-1.04-3.865-3.12C23.999 11.957 49.746-1.93 83.512.214z"
          id="path-25"
        />
        <filter
          x="-0.3%"
          y="-0.9%"
          width="100.7%"
          height="103.6%"
          filterUnits="objectBoundingBox"
          id="filter-26"
        >
          <feOffset dy={1} in="SourceAlpha" result="shadowOffsetOuter1" />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
            in="shadowOffsetOuter1"
          />
        </filter>
        <filter
          x="-68.9%"
          y="-274.3%"
          width="237.8%"
          height="648.5%"
          filterUnits="objectBoundingBox"
          id="filter-27"
        >
          <feGaussianBlur stdDeviation={22.5} in="SourceGraphic" />
        </filter>
        <path
          d="M125.477 63.26c11.632 3.335 18.819 8.688 21.562 16.058l-1.51-.087c.054-.577.115-1.138.184-1.683-5.248-3.989-24.207-12.858-66.464-16.19-52.686-4.125-71.387 7.441-72.59 9.726l-.063.188c3.656-11.428 21.48-17.142 53.47-17.142 37.958-.035 53.455 6.152 65.411 9.13z"
          id="path-28"
        />
        <filter
          x="-2.1%"
          y="-11.9%"
          width="104.3%"
          height="123.8%"
          filterUnits="objectBoundingBox"
          id="filter-29"
        >
          <feOffset in="SourceAlpha" result="shadowOffsetOuter1" />
          <feGaussianBlur
            stdDeviation={1}
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g id="v2" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Desktop-HD---Day" transform="translate(-777 -184)">
          <g id="homepage-hero" transform="translate(208 184)">
            <g id="image" transform="translate(569)">
              <g id="background" transform="translate(0 .73)">
                <mask id="mask-3" fill="#fff">
                  <use xlinkHref="#path-2" />
                </mask>
                <g id="bubbles">
                  <use fill="#FFF" xlinkHref="#path-2" />
                  <use
                    fillOpacity={0.25}
                    fill="url(#linearGradient-1)"
                    xlinkHref="#path-2"
                  />
                  <use
                    fill="#000"
                    filter="url(#filter-4)"
                    xlinkHref="#path-2"
                  />
                </g>
                <g id="avatar" mask="url(#mask-3)">
                  <g transform="translate(212 97)">
                    <g id="Body" transform="translate(27 131)">
                      <mask id="mask-6" fill="#fff">
                        <use xlinkHref="#path-5" />
                      </mask>
                      <use fill="#D0C6AC" xlinkHref="#path-5" />
                      <g
                        id="\uD83C\uDFA8-Skin"
                        mask="url(#mask-6)"
                        fill="#EDC8A6"
                      >
                        <path
                          transform="translate(-32 -117)"
                          id="Color"
                          d="M0 0H264V244H0z"
                        />
                      </g>
                    </g>
                    <g id="clothes" transform="translate(0 161)">
                      <mask id="mask-8" fill="#fff">
                        <use xlinkHref="#path-7" />
                      </mask>
                      <use id="hoodie" fill="#B7C1DB" xlinkHref="#path-7" />
                      <g id="\u21B3-Color-Fabric" mask="url(#mask-8)">
                        <g transform="translate(-19 -13)">
                          <path
                            id="\uD83D\uDD8DColor"
                            fill="#1C3422"
                            d="M0 0.269271383H292V183.269271383H0z"
                          />
                          <rect
                            id="Rectangle"
                            fillOpacity={0.16}
                            fill="#000"
                            x={79}
                            y={125}
                            width={7}
                            height={71}
                            rx={3.5}
                          />
                          <rect
                            id="Rectangle"
                            fillOpacity={0.16}
                            fill="#000"
                            x={211}
                            y={132}
                            width={7}
                            height={71}
                            rx={3.5}
                          />
                        </g>
                      </g>
                      <path
                        d="M96.999 48.739L97 97h-7l-.002-51.85A64.539 64.539 0 0097 48.738zm67.002-3.59L164 85.5a3.5 3.5 0 11-7 0V48.739a64.54 64.54 0 007-3.59z"
                        id="Straps"
                        fill="#F4F4F4"
                        mask="url(#mask-8)"
                      />
                      <path
                        d="M168.036-.276C183.09 2.57 193.5 8.242 193.5 19.308 193.5 39.02 160.462 55 127 55S60.5 39.02 60.5 19.308C60.5 8.242 70.911 2.57 85.964-.276 76.877 3.073 71 8.704 71 18.077 71 38.469 98.821 55 127 55s56-16.531 56-36.923C183 8.9 177.366 3.31 168.601-.064z"
                        id="Shadow"
                        fillOpacity={0.16}
                        fill="#000"
                        mask="url(#mask-8)"
                      />
                    </g>
                    <g id="face" transform="translate(48.6)">
                      <path
                        d="M54.317 173.998c2.402-3.933 9.414-6.15 21.036-6.653 11.622-.503 20.638 1.296 27.047 5.395-.083 1.79.033 3.789 0 5.912-22.12.92-34.105.842-48.083 4.737.051-2.3 0-7.502 0-9.39z"
                        id="neck-shadow"
                        fill="#000"
                        opacity={0.200000003}
                        transform="matrix(1 0 0 -1 0 350.652)"
                      />
                      <g
                        id="ears"
                        transform="translate(.4 65.917)"
                        fill="#EDC8A6"
                      >
                        <path
                          d="M5.912 0c6.42 0 9.496 9.648 9.496 16.068l-1.372 14.256c0 6.42 1.01 13.356-5.41 13.356s-7.321-7.685-7.321-14.106L.01 13.981C.01 7.561-.508 0 5.912 0zM155.769 13.057c-5.321-.56-9.032 5.788-10.51 11.614l-.14.58-.125.576c-.04.19-.076.38-.11.569l-.095.56-.042.277-.072.544-.03.268-.125 14.321-.089.8-.355 2.923-.115 1.04-.049.512-.042.506-.034.498-.025.49-.015.481c-.065 3.492.806 6.188 4.708 6.598 4.287.45 6.462-2.805 7.632-6.925l.144-.53.133-.54c.022-.09.043-.18.063-.271l.119-.548.11-.553.051-.278.097-.558.09-.56.081-.559.075-.558.101-.833.06-.55 2.918-15.372.294-2.702.082-.816.074-.813.06-.805.032-.532c.005-.088.01-.176.013-.263l.019-.523.006-.259.003-.511-.008-.503c-.1-3.57-1.17-6.394-4.984-6.795z"
                          id="Rectangle"
                        />
                      </g>
                      <path
                        d="M12.87 54.272c0-16.945 2.243-57.692 72.501-53.27 70.259 4.422 68.788 42.446 66.616 59.546-2.172 17.1 3.728 49.988-16.963 86.206-8.263 11.957-36.33 27.893-41.812 29.9-5.481 2.005-30.664.116-34.414-2.007S24.923 152.3 17.731 133.923c-11.876-27.784-4.86-62.706-4.86-79.651z"
                        fill="#EDC8A6"
                      />
                      <g
                        id="shadows-and-highlights"
                        transform="translate(28.873 71.967)"
                      >
                        <circle
                          id="Oval"
                          fillOpacity={0.61}
                          fill="#F045FF"
                          filter="url(#filter-9)"
                          cx={88.875}
                          cy={43.875}
                          r={11.625}
                        />
                        <circle
                          id="Oval"
                          fillOpacity={0.61}
                          fill="#F045FF"
                          filter="url(#filter-10)"
                          cx={11.625}
                          cy={37.875}
                          r={11.625}
                        />
                        <path
                          d="M34.125 0C39.717 0 44.25 6.548 44.25 14.625S39.717 29.25 34.125 29.25c-.64 0-1.267-.086-1.875-.25 4.696-1.271 8.25-7.224 8.25-14.375S36.946 1.521 32.25.251A7.142 7.142 0 0134.126 0z"
                          id="Combined-Shape"
                          fillOpacity={0.5}
                          fill="#626262"
                          filter="url(#filter-11)"
                        />
                        <path
                          d="M63.376 2.25c5.592 0 10.125 6.548 10.125 14.625S68.968 31.5 63.376 31.5c-.641 0-1.268-.086-1.875-.25 4.696-1.271 8.25-7.224 8.25-14.375S66.197 3.771 61.5 2.501a7.142 7.142 0 011.875-.251z"
                          id="Combined-Shape"
                          fillOpacity={0.5}
                          fill="#626262"
                          filter="url(#filter-12)"
                          transform="scale(-1 1) rotate(-5 0 1562.884)"
                        />
                      </g>
                      <g id="mouth" transform="rotate(4 -1849.1 800.047)">
                        <mask id="mask-14" fill="#fff">
                          <use xlinkHref="#path-13" />
                        </mask>
                        <use
                          id="mouth"
                          fillOpacity={0.699999988}
                          fill="#000"
                          xlinkHref="#path-13"
                        />
                        <path
                          d="M6.43-5.723h42.376a3.188 3.188 0 013.187 3.188v.557a3.75 3.75 0 01-2.641 3.582c-5.26 1.628-12.408 2.442-21.445 2.442-9.104 0-16.432-.826-21.984-2.478a3.75 3.75 0 01-2.68-3.594v-.51a3.188 3.188 0 013.188-3.187z"
                          id="teeth"
                          fill="#FFF"
                          mask="url(#mask-14)"
                        />
                        <g id="Tongue" mask="url(#mask-14)" fill="#FF4F6D">
                          <g transform="translate(4.639 14.523)">
                            <ellipse
                              cx={15.8888889}
                              cy={13.875}
                              rx={15.8888889}
                              ry={13.875}
                            />
                            <ellipse
                              cx={30.3333333}
                              cy={13.875}
                              rx={15.8888889}
                              ry={13.875}
                            />
                          </g>
                        </g>
                        <path
                          d="M5.374 22.169h42.375a3.188 3.188 0 013.187 3.187v.557a3.75 3.75 0 01-2.64 3.583c-5.26 1.628-12.409 2.442-21.446 2.442-9.104 0-16.432-.826-21.984-2.479a3.75 3.75 0 01-2.68-3.594v-.509a3.188 3.188 0 013.188-3.187z"
                          id="Teeth"
                          fill="#FFF"
                          mask="url(#mask-14)"
                          transform="matrix(1 0 0 -1 0 54.107)"
                        />
                      </g>
                      <g id="facial-hair" transform="translate(10.812 41.669)">
                        <path
                          d="M2.058 12.603l6.024-7.318c.998-.4-15.174 80.12 13.215 81.989 6.586.433 32.494 39.588 45.138 40.528 10.557.784 39.985-34.862 50.354-34.438 14.24.583 21.361-25.136 21.361-77.157l3.024 2.672c-2.172 17.1 3.729 49.988-16.962 86.206-8.263 11.957-36.33 27.894-41.812 29.9-5.481 2.005-30.664.116-34.414-2.006-3.75-2.123-33.875-22.35-41.067-40.725C-4.958 64.47 2.058 29.548 2.058 12.603z"
                          id="Path-7"
                          fill="#BA916C"
                          opacity={0.5}
                        />
                        <path
                          d="M51.27 80.49H84c11.016 0 21.828 5.518 21.828 7.594 0 1.385-1.217 2.077-3.653 2.077a620.143 620.143 0 00-33.87-.868 577.518 577.518 0 00-33.069 1.025c-2.435 0-3.653-.692-3.653-2.077 0-2.076 8.727-7.75 19.688-7.75z"
                          id="Rectangle"
                          fill="#907040"
                          transform="rotate(3 68.705 85.404)"
                        />
                        <path
                          d="M90.206 12.582c0 3.042 3.513 3.595 4.995 3.59 4.1 0 11.246-4.418 17.552-4.254 9.754.017 10.667 9.375 13.552 9.53.675 0 1.09-.314 1.104-1.765 0-3.077-7.649-15.706-20.146-15.919-4.929-.11-17.345 1.303-17.057 8.818zM56.805 11.582l-.037.266c-.25 2.039-1.951 2.756-3.483 2.95l-.337.035-.327.02a8.117 8.117 0 01-.159.004h-.305l-.287-.01-.262-.019-.236-.025c-1.21-.148-2.64-.717-4.216-1.46l-.874-.42-.9-.446-1.396-.697-.955-.47c-2.734-1.33-5.677-2.59-8.562-2.868-4.49-.544-7.339 1.139-9.39 3.08l-.375.366c-.122.123-.242.246-.358.37l-.342.37-.327.367-.464.54-.854 1.003-.266.302-.26.28-.254.256c-.586.567-1.137.913-1.721.873-.67-.082-1.044-.445-.881-1.886a3.5 3.5 0 01.231-.826l.119-.282a7.95 7.95 0 01.068-.147l.153-.308.175-.324c.03-.056.063-.112.096-.168l.208-.346.23-.359c.04-.06.08-.122.122-.183l.262-.376.282-.385.302-.393.159-.2.332-.403.174-.204.362-.41c.061-.07.124-.138.188-.207l.39-.416c.2-.208.406-.416.62-.624l.436-.416.454-.415.47-.41.49-.407c3.892-3.163 9.33-5.799 15.613-5.136l.559.063.402.053.64.096.451.075.467.085.483.094.495.104.506.113.517.124.524.134.53.144.535.155.538.165.539.177.538.187.536.199.531.21.526.222.26.115.513.24c3.477 1.676 6.239 4.196 5.802 7.81z"
                          id="Path-5"
                          fill="#907040"
                        />
                        <path
                          d="M50.492 115.548c.043-1.385 8.773 4.098 15.943 4.487 7.17.388 16.036-4.487 15.965-2.207-.072 2.28-6.836 10.459-16.207 9.951-9.371-.508-15.744-10.846-15.701-12.23zM54.242 133.17c.024-.781 7.205 1.067 11.25 1.286 4.045.22 10.54-.536 10.5.75-.04 1.286-3.62 10.523-11.59 10.09-7.969-.431-10.185-11.345-10.16-12.126z"
                          id="Path-6"
                          fill="#907040"
                        />
                      </g>
                      <g id="nose" transform="translate(65.712 98.188)">
                        <g id="Oval">
                          <use
                            fill="#000"
                            filter="url(#filter-17)"
                            xlinkHref="#path-16"
                          />
                          <use
                            fill="url(#linearGradient-15)"
                            xlinkHref="#path-16"
                          />
                        </g>
                        <path
                          d="M4.365 12.785c.487-2.608 5.171-4.25 10.462-3.668 5.29.582 9.184 3.168 8.697 5.776-.056.3-.167.586-.328.858-.905-2.024-4.251-3.78-8.496-4.248-4.683-.515-8.891.713-10.133 2.811a2.548 2.548 0 01-.202-1.529z"
                          id="Combined-Shape"
                          fillOpacity={0.2}
                          fill="#FFF"
                          filter="url(#filter-18)"
                        />
                        <ellipse
                          id="Oval"
                          fill="#FFF"
                          filter="url(#filter-19)"
                          cx={21.1080124}
                          cy={6.375}
                          rx={2.31156831}
                          ry={2.625}
                        />
                      </g>
                      <g id="eyes" transform="translate(29.33 67.835)">
                        <g id="left">
                          <mask id="mask-21" fill="#fff">
                            <use xlinkHref="#path-20" />
                          </mask>
                          <use
                            id="Path-4"
                            fill="#EFEFEF"
                            xlinkHref="#path-20"
                          />
                          <path
                            d="M18.533.08C36.616 1.127 37.01 17.266 37.01 19.49l-.01.14c-.118-3.67-1.572-17.822-18.467-18.8C6.956-.198.44 8.993.022 13.02-.428 9.55 6.164-1.018 18.532.08z"
                            id="Combined-Shape"
                            fill="#22170F"
                            mask="url(#mask-21)"
                          />
                          <circle
                            id="Oval"
                            fill="url(#linearGradient-22)"
                            mask="url(#mask-21)"
                            cx={19.7920022}
                            cy={14.6315565}
                            r={11.25}
                          />
                          <circle
                            id="Oval"
                            fill="#000"
                            mask="url(#mask-21)"
                            cx={19.7920022}
                            cy={14.6315565}
                            r={7.5}
                          />
                          <circle
                            id="Oval"
                            fill="#FFF"
                            mask="url(#mask-21)"
                            cx={15.6670022}
                            cy={9.75655645}
                            r={2.625}
                          />
                        </g>
                        <g id="right" transform="rotate(9 19.521 447.85)">
                          <mask id="mask-24" fill="#fff">
                            <use xlinkHref="#path-23" />
                          </mask>
                          <use
                            id="Path-4"
                            fill="#EFEFEF"
                            xlinkHref="#path-23"
                          />
                          <path
                            d="M18.477.08C6.361.78 2.187 8.258.75 13.785l-.121.49c-.02.08-.038.16-.056.24l-.1.471c-.016.077-.032.154-.046.23l-.083.45-.037.22-.065.425-.056.407-.047.388-.054.542-.038.49-.025.435-.014.373L0 19.49l.01.144.015-.357.024-.41.022-.301.045-.49.038-.35.072-.56.06-.392c.02-.134.043-.27.067-.408l.078-.422c.042-.214.087-.433.137-.656l.106-.45C2.036 9.284 6.139 1.543 18.477.83c8.22-.73 13.887 3.69 16.634 7.758l.225.343c.109.17.213.34.311.51l.19.335.09.166.17.328.154.324.14.317c.045.104.087.208.127.31l.112.303c.192.547.312 1.053.358 1.496.1-.764-.143-1.871-.712-3.122l-.163-.344a13.015 13.015 0 00-.088-.175l-.188-.354c-2.38-4.332-8.33-9.747-17.36-8.945z"
                            id="Path"
                            fill="#22170F"
                            mask="url(#mask-24)"
                          />
                          <circle
                            id="Oval"
                            fill="url(#linearGradient-22)"
                            mask="url(#mask-24)"
                            cx={16.8289371}
                            cy={15.1008598}
                            r={11.25}
                          />
                          <circle
                            id="Oval"
                            fill="#000"
                            mask="url(#mask-24)"
                            cx={16.8289371}
                            cy={15.1008598}
                            r={7.5}
                          />
                          <circle
                            id="Oval"
                            fill="#FFF"
                            mask="url(#mask-24)"
                            cx={11.9631709}
                            cy={10.3431857}
                            r={2.625}
                          />
                        </g>
                      </g>
                      <path
                        d="M12.87 54.272c.12-1.729 18.307-14.169 72.652-9.914 42.258 3.332 61.217 12.201 66.465 16.19-.227 1.791-.366 3.755-.457 5.878C96.56 50.09 54.4 46.953 12.644 60.571c.139-2.3.226-4.411.226-6.3z"
                        id="shadow"
                        fill="#000"
                        opacity={0.473214286}
                      />
                    </g>
                  </g>
                </g>
                <path
                  d="M357.05 15.27c7.341 0 14.07 2.598 19.298 6.917a30.485 30.485 0 014.152-.281c10.22 0 18.658 5.036 19.933 11.555C408.606 38.801 414 47.98 414 58.406c0 16.493-13.499 29.863-30.15 29.863-4.817 0-9.37-1.118-13.408-3.108a30.147 30.147 0 01-13.392 3.108 30.206 30.206 0 01-17.935-5.856 23.476 23.476 0 01-15.565 5.856c-3.597 0-7.004-.802-10.05-2.235a23.534 23.534 0 01-10.05 2.235C290.499 88.27 280 77.87 280 65.042c0-9.87 6.215-18.302 14.98-21.666 3.393-8.678 11.906-14.834 21.87-14.834a23.5 23.5 0 0112.935 3.85c4.818-10.122 15.216-17.123 27.265-17.123z"
                  id="cloud3"
                  fill="#FFF"
                  mask="url(#mask-3)"
                />
                <path
                  d="M173.5 108.27c5.894 0 11.026 3.281 13.693 8.129a10.314 10.314 0 017.164-2.88c5.319 0 9.707 4.009 10.348 9.189A15.756 15.756 0 01210 134.519c0 8.699-7.004 15.75-15.643 15.75-4.006 0-7.66-1.516-10.428-4.01a15.525 15.525 0 01-10.429 4.01c-2.037 0-3.983-.392-5.768-1.105a10.304 10.304 0 01-4.66 1.105 10.373 10.373 0 01-7.824-3.557 10.357 10.357 0 01-7.82 3.557c-5.759 0-10.428-4.7-10.428-10.5 0-5.535 4.254-10.07 9.65-10.47l.779-.03.028-.783c.398-5.433 4.902-9.717 10.4-9.717.296 0 .589.013.879.037 2.135-6.136 7.94-10.537 14.764-10.537z"
                  id="cloud2"
                  fill="#FFF"
                  mask="url(#mask-3)"
                />
                <path
                  d="M114.5 62.27c3.633 0 6.797 2.03 8.441 5.032a6.344 6.344 0 014.416-1.783c3.279 0 5.984 2.482 6.38 5.688A9.772 9.772 0 01137 78.52c0 5.385-4.317 9.75-9.643 9.75a9.548 9.548 0 01-6.428-2.482 9.548 9.548 0 01-6.429 2.482 9.525 9.525 0 01-3.556-.684 6.383 6.383 0 01-7.695-1.518 6.373 6.373 0 01-4.82 2.202c-3.55 0-6.429-2.91-6.429-6.5 0-3.426 2.622-6.234 5.949-6.482l.48-.018.017-.485c.246-3.363 3.022-6.015 6.411-6.015.183 0 .363.008.542.023 1.316-3.799 4.894-6.523 9.101-6.523z"
                  id="cloud1"
                  fill="#FFF"
                  mask="url(#mask-3)"
                />
              </g>
              <g id="hat" transform="translate(266.874 80.73)">
                <path
                  d="M145.02 95.719c.11-7.146.074-13.3.693-18.17-5.248-3.99-24.207-12.859-66.464-16.19-54.346-4.256-72.532 8.184-72.653 9.913 0 3.468-.294 7.689-.66 12.447C1.98 82.321 0 77.172 0 68.272c.173-8.313 2.264-17.11 6.272-26.395C23.999 11.957 49.746-1.93 83.512.215c18.911 1.621 34.253 7.82 48.435 20.047 8.057 8.106 24.918 29.493 23.496 59.056-.744 7.137-4.088 12.526-10.032 16.167z"
                  id="Path-9"
                  fill="#41553D"
                />
                <g id="Path-8">
                  <use
                    fill="#000"
                    filter="url(#filter-26)"
                    xlinkHref="#path-25"
                  />
                  <use fill="#41553D" xlinkHref="#path-25" />
                </g>
                <path
                  id="Rectangle-10"
                  stroke="#979797"
                  strokeWidth={0.75}
                  fill="#FFF"
                  style={{
                    mixBlendMode: "overlay",
                  }}
                  filter="url(#filter-27)"
                  d="M30.106278 40.0072875H127.606278V58.0072875H30.106278z"
                />
                <g id="Combined-Shape">
                  <use
                    fill="#000"
                    filter="url(#filter-29)"
                    xlinkHref="#path-28"
                  />
                  <use fill="#57553F" xlinkHref="#path-28" />
                </g>
                <path
                  d="M125.477 64.01c11.224 3.218 18.31 8.315 21.257 15.29l-1.205-.07c.054-.577.115-1.138.184-1.682l-.161-.12-.17-.125c-5.685-4.097-24.721-12.68-66.133-15.945-48.85-3.825-68.483 5.84-72.043 9.089C11.96 60.068 29.58 54.88 60.066 54.88c37.958-.035 53.455 6.152 65.411 9.13z"
                  id="Combined-Shape"
                  fill="#393826"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}
