import * as React from "react"

export default function Dribbble(props) {
  return (
    <svg width={44} height={44} viewBox="0 0 44 44" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M0 0H44V44H0z" />
        <circle cx={22} cy={22} r={21.12} fill="#EA4C89" fillRule="nonzero" />
        <path
          fill="#C32361"
          d="M22 0C9.855 0 0 9.855 0 22s9.855 22 22 22c12.121 0 22-9.855 22-22S34.121 0 22 0zm14.531 10.141a18.705 18.705 0 014.248 11.692c-.62-.12-6.825-1.384-13.076-.597-.143-.31-.263-.644-.406-.978a55.922 55.922 0 00-1.24-2.72c6.92-2.816 10.069-6.872 10.474-7.397zM22 3.245c4.772 0 9.139 1.79 12.456 4.725-.335.477-3.174 4.27-9.855 6.776-3.078-5.655-6.49-10.284-7.015-11a19.11 19.11 0 014.414-.5zm-7.993 1.766c.5.668 3.841 5.32 6.967 10.857-8.78 2.338-16.536 2.29-17.371 2.29C4.82 12.336 8.757 7.492 14.007 5.011zM3.197 22.024v-.573c.812.024 9.927.143 19.304-2.672a55.894 55.894 0 011.527 3.197c-.238.072-.5.143-.74.215-9.687 3.126-14.841 11.668-15.27 12.384a18.748 18.748 0 01-4.82-12.551zM22 40.803a18.66 18.66 0 01-11.525-3.961c.334-.692 4.152-8.042 14.746-11.74.048-.024.072-.024.12-.048 2.648 6.848 3.722 12.599 4.008 14.245A18.482 18.482 0 0122 40.803zm10.475-3.222c-.19-1.145-1.193-6.633-3.65-13.386 5.893-.93 11.047.597 11.691.812-.811 5.225-3.818 9.735-8.04 12.574z"
        />
      </g>
    </svg>
  )
}
