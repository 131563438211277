import * as React from "react"

export default function GitHub(props) {
  return (
    <svg width={44} height={43} viewBox="0 0 44 43" {...props}>
      <g fill="#161514" fillRule="evenodd">
        <path d="M21.96 0C9.832 0 0 9.83 0 21.959c0 9.702 6.292 17.933 15.017 20.837 1.098.203 1.5-.476 1.5-1.056 0-.524-.02-2.254-.03-4.089-6.108 1.329-7.398-2.59-7.398-2.59-.998-2.539-2.438-3.214-2.438-3.214-1.992-1.363.15-1.335.15-1.335 2.206.155 3.367 2.263 3.367 2.263 1.958 3.357 5.137 2.387 6.39 1.826.197-1.42.766-2.389 1.394-2.937-4.877-.555-10.005-2.438-10.005-10.852 0-2.397.858-4.356 2.263-5.894-.228-.554-.98-2.787.213-5.811 0 0 1.844-.59 6.04 2.25 1.752-.486 3.63-.73 5.496-.738 1.866.008 3.746.252 5.501.738 4.192-2.84 6.033-2.25 6.033-2.25 1.195 3.024.443 5.257.215 5.81 1.408 1.539 2.26 3.498 2.26 5.895 0 8.434-5.137 10.291-10.027 10.835.788.681 1.49 2.018 1.49 4.066 0 2.938-.026 5.303-.026 6.027 0 .584.396 1.269 1.509 1.053 8.72-2.907 15.004-11.135 15.004-20.834C43.918 9.83 34.087 0 21.96 0" />
        <path d="M8.317 31.528c-.048.11-.22.142-.376.067-.16-.071-.249-.22-.197-.33.047-.112.219-.143.378-.068.16.072.25.222.195.331m.89.992c-.105.097-.31.052-.449-.101-.143-.153-.17-.358-.064-.456.108-.098.306-.052.45.101.144.155.172.358.063.456m.865 1.265c-.134.094-.354.006-.49-.19-.135-.195-.135-.429.003-.523.136-.093.353-.01.49.185.135.198.135.432-.003.528m1.187 1.222c-.12.133-.377.097-.565-.084-.192-.177-.245-.428-.125-.561.122-.133.38-.096.57.084.19.177.248.43.12.56m1.636.71c-.053.172-.3.25-.549.177-.248-.075-.41-.276-.36-.45.051-.173.3-.255.55-.176.248.074.41.274.359.45m1.797.131c.006.181-.205.331-.466.334-.262.006-.475-.14-.478-.318 0-.183.207-.332.47-.336.26-.005.474.14.474.32m1.672-.285c.032.177-.15.358-.41.406-.254.047-.49-.062-.522-.237-.032-.181.153-.363.407-.41.26-.045.492.062.525.241" />
      </g>
    </svg>
  )
}
