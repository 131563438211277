import * as React from "react"

export default function Spotify(props) {
  return (
    <svg width={44} height={44} viewBox="0 0 44 44" {...props}>
      <path
        fill="#1ED760"
        fillRule="evenodd"
        d="M35.014 19.503c-7.092-4.211-18.79-4.598-25.559-2.544a2.057 2.057 0 11-1.195-3.938c7.772-2.358 20.69-1.903 28.854 2.943a2.058 2.058 0 01-2.1 3.54zm-.232 6.238a1.716 1.716 0 01-2.36.565C26.51 22.672 17.495 21.62 10.5 23.742A1.718 1.718 0 018.36 22.6a1.717 1.717 0 011.143-2.14c7.99-2.424 17.924-1.25 24.714 2.923a1.715 1.715 0 01.565 2.358zm-2.692 5.99a1.37 1.37 0 01-1.886.457c-5.166-3.157-11.669-3.87-19.327-2.121a1.37 1.37 0 11-.61-2.673c8.38-1.916 15.569-1.091 21.368 2.452.646.395.85 1.24.455 1.886zM22 0C9.85 0 0 9.85 0 22 0 34.15 9.85 44 22 44s22-9.849 22-22C44 9.85 34.15 0 22 0z"
      />
    </svg>
  )
}
